/* eslint-disable jsx-a11y/no-onchange */

import { navigate } from 'gatsby'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import * as styles from './state-selectors.module.css'

export function DealersStateSelector(props) {
  const { selected } = props

  // TODO: get data from server query
  const usOptions = useMemo(() => US_STATES, [])
  const caOptions = useMemo(() => CA_STATES, [])

  const findSelectedTitle = useCallback(() => {
    if (!selected) return

    const option = [...usOptions, ...caOptions].find(
      (opt) => opt.value === selected,
    )

    if (!option) return

    return option.label
  }, [selected, usOptions, caOptions])

  const initialSelectedTitle = findSelectedTitle()
  const [selectedTitle, setSelectedTitle] = useState(initialSelectedTitle)

  useEffect(() => {
    const nextSelected = findSelectedTitle()
    setSelectedTitle(nextSelected)
  }, findSelectedTitle)

  const handleChange = useCallback((evt) => {
    evt.preventDefault()

    const country = evt.target.getAttribute('data-country')
    const state = evt.target.value

    navigate(`/dealers/${country}/${state}`)
  }, [])

  return (
    <div className={styles.selector}>
      <div className={styles.fields}>
        <div className={styles.field}>
          <label className={styles.label} htmlFor="select-us">
            United States
          </label>

          <select
            className={styles.select}
            data-country="usa"
            id="select-us"
            onChange={handleChange}
          >
            <option>Choose State</option>

            {usOptions.map((option, i) => (
              <option key={i} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>

        <div className={styles.field}>
          <label className={styles.label} htmlFor="select-ca">
            Canada
          </label>

          <select
            className={styles.select}
            data-country="ca"
            id="select-ca"
            onChange={handleChange}
          >
            <option>Choose a Province</option>

            {caOptions.map((option, i) => (
              <option key={i} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      </div>

      {selectedTitle && (
        <h2 className={styles.selectedTitle}>{selectedTitle}</h2>
      )}
    </div>
  )
}
DealersStateSelector.propTypes = {
  selected: PropTypes.string,
}

const CA_STATES = [{ value: 'QC', label: 'Quebec' }]

const US_STATES = [
  { value: 'AL', label: 'ALABAMA' },
  { value: 'AZ', label: 'ARIZONA' },
  { value: 'CA', label: 'CALIFORNIA' },
  { value: 'CO', label: 'COLORADO' },
  { value: 'CT', label: 'CONNECTICUT' },
  { value: 'DE', label: 'DELAWARE' },
  { value: 'FL', label: 'FLORIDA' },
  { value: 'GA', label: 'GEORGIA' },
  { value: 'ID', label: 'IDAHO' },
  { value: 'IL', label: 'ILLINOIS' },
  { value: 'IN', label: 'INDIANA' },
  { value: 'IA', label: 'IOWA' },
  { value: 'KS', label: 'KANSAS' },
  { value: 'KY', label: 'KENTUCKY' },
  { value: 'LA', label: 'LOUISIANA' },
  { value: 'MD', label: 'MARYLAND' },
  { value: 'MA', label: 'MASSACHUSETTS' },
  { value: 'MI', label: 'MICHIGAN' },
  { value: 'MN', label: 'MINNESOTA' },
  { value: 'MO', label: 'MISSOURI' },
  { value: 'MT', label: 'MONTANA' },
  { value: 'NE', label: 'NEBRASKA' },
  { value: 'NV', label: 'NEVADA' },
  { value: 'NJ', label: 'NEW JERSEY' },
  { value: 'NM', label: 'NEW MEXICO' },
  { value: 'NY', label: 'NEW YORK' },
  { value: 'NC', label: 'NORTH CAROLINA' },
  { value: 'ND', label: 'NORTH DAKOTA' },
  { value: 'OH', label: 'OHIO' },
  { value: 'OK', label: 'OKLAHOMA' },
  { value: 'OR', label: 'OREGON' },
  { value: 'PA', label: 'PENNSYLVANIA' },
  { value: 'SC', label: 'SOUTH CAROLINA' },
  { value: 'TN', label: 'TENNESSEE' },
  { value: 'TX', label: 'TEXAS' },
  { value: 'UT', label: 'UTAH' },
  { value: 'VT', label: 'VERMONT' },
  { value: 'VA', label: 'VIRGINIA' },
  { value: 'WA', label: 'WASHINGTON' },
  { value: 'WI', label: 'WISCONSIN' },
  { value: 'WY', label: 'WYOMING' },
]
