import cx from 'classnames'
import React from 'react'
import PropTypes from 'prop-types'

import * as styles from './dealer-card.module.css'

export function DealerCard(props) {
  const { dealer } = props

  return (
    <div className={cx('h-card', styles.card)}>
      <span className={cx('p-name', styles.name)}>{dealer.name}</span>

      <div className="p-street-address">
        <span>{dealer.address01}</span>
        {dealer.address02 && <span>{dealer.address02}</span>}
      </div>

      <div>
        <span className="p-locality">{dealer.city}</span>
        {', '}
        <span className="p-region">{dealer.state}</span>
        {', '}
        <span className="p-postal-code">{dealer.zip}</span>
      </div>

      {/* TODO: format phone numbers */}
      <div className="p-tel">{dealer.phone01}</div>
      {dealer.phone02 && <div className="p-tel">{dealer.phone02}</div>}

      <div className={cx('p-website', styles.website)}>
        <a href={dealer.website}>{dealer.website}</a>
      </div>
    </div>
  )
}

export const DealerProp = PropTypes.shape({
  address01: PropTypes.string.isRequired,
  address02: PropTypes.string,
  city: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  phone01: PropTypes.string.isRequired,
  phone02: PropTypes.string,
  state: PropTypes.string.isRequired,
  website: PropTypes.string,
  zip: PropTypes.string.isRequired,
})
DealerCard.propTypes = {
  dealer: PropTypes.shape(DealerProp).isRequired,
}
