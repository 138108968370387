import React from 'react'
import PropTypes from 'prop-types'

import { MainLayout } from '../../layouts/main'
import { SectionTitle } from '../section-title'

import { DealerCard, DealerProp } from './dealer-card'
import { DealersStateSelector } from './state-selector'

import * as styles from './state.module.css'

export default function DealersState(props) {
  const {
    pageContext: { dealers, state },
  } = props

  return (
    <MainLayout>
      <SectionTitle>Find a Dealer</SectionTitle>
      <DealersStateSelector selected={state} />

      <div className={styles.cards}>
        {dealers.map((dealer) => (
          <DealerCard dealer={dealer} key={dealer.id} />
        ))}
      </div>
    </MainLayout>
  )
}

DealersState.propTypes = {
  pageContext: PropTypes.shape({
    dealers: PropTypes.arrayOf(DealerProp.isRequired).isRequired,
    state: PropTypes.string.isRequired,
  }).isRequired,
}
